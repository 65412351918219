
// Custom Theming for Angular Material
@use '@angular/material' as mat;
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$mat-t-magenta: (50: #fce0ee,
    100: #f6b3d5,
    200: #f180ba,
    300: #eb4d9e,
    400: #e62689,
    500: #e20074,
    600: #df006c,
    700: #da0061,
    800: #d60057,
    900: #cf0044,
    A100: #fff6f9,
    A200: #ffc3d3,
    A400: #ff90ad,
    A700: #ff779a,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ));

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$telekom-angular-template-primary: mat.define-palette($mat-t-magenta);
$telekom-angular-template-accent: mat.define-palette($mat-t-magenta, A200, A100, A400);

// The warn palette is optional (defaults to red).
$telekom-angular-template-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$telekom-angular-template-theme: mat.define-light-theme($telekom-angular-template-primary, $telekom-angular-template-accent, $telekom-angular-template-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($telekom-angular-template-theme);

/* You can add global styles to this file, and also import other style files */

.layout {
    // XS
    margin-left: 12px;
    margin-right: 12px;
    
    // L
    @media screen and (min-width: 960px){
        max-width: 900px;
        margin: auto;
    }
    
    // XL
    @media screen and (min-width: 1140px){
        max-width: 1000px;
    }

    // XXL
    @media screen and (min-width: 1400px){
        max-width: 1200px;
    }
}

@font-face {
    font-family: 'TeleGroteskNext';
    src: url("assets/fonts/telegrotesknext-regular.woff") format("woff"),
        url("assets/fonts/telegrotesknext-regular.woff2") format("woff2");
}

@font-face {
    font-family: 'TeleGroteskNext';
    src: url("assets/fonts/telegrotesknext-regularitalic.woff") format("woff"),
        url("assets/fonts/telegrotesknext-regularitalic.woff2") format("woff2");
    font-style: italic;
}

@font-face {
    font-family: 'TeleGroteskNext';
    src: url("assets/fonts/telegrotesknext-bold.woff") format("woff"),
        url("assets/fonts/telegrotesknext-bold.woff2") format("woff2");
    font-weight: bold;
}


@font-face {
    font-family: 'TeleGroteskNext';
    src: url("assets/fonts/telegrotesknext-bolditalic.woff") format("woff"),
        url("assets/fonts/telegrotesknext-bolditalic.woff2") format("woff2");
    font-weight: bold;
    font-style: italic;
}

* {
    font-family: TelegroteskNext, Roboto, "Helvetica Neue", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

a {
    color: #000000;
    text-decoration: underline;
}

html, body { height: 100%; }
body { margin: 0; }

/*************************************************************** 
    Snackbar classes
****************************************************************/

/**
    @param $background-color Color of the background
    @param $color Color of the text
*/
@mixin snackbar($background-color, $color) {
    background-color: $background-color;
    & span {
        font-weight: bold;
        color: $color;
    }

    & .mat-simple-snackbar-action {
        color: $color;
    }
}

.snackbar-error {
    @include snackbar(mat-color($mat-red, 700), $light-primary-text)
}

.snackbar-success {
    @include snackbar(mat-color($mat-green, 700), $light-primary-text)
}

.snackbar-info {
    @include snackbar(mat-color($mat-blue, 700), $light-primary-text)
}

.snackbar-warn {
    @include snackbar(mat-color($mat-amber, 700), $light-primary-text)
}

.snackbar-magenta {
    @include snackbar(mat-color($mat-t-magenta, 700), $light-primary-text)
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #4ee19c;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #8ff0c1;
}

.mat-form-field-flex:hover .mat-form-field-outline {
    opacity: 0;
    transition: opacity .6s cubic-bezier(.25,.8,.25,1);
    color: mat-color($mat-t-magenta, 500);
}

.mat-tab-label, .mat-tab-link{
    font-size: 15px;
    font-weight: 600;
}

.mat-tab-label-active{
    opacity: 1 !important;
  }
  